//import Vue from 'vue';
import * as func from './functions/_function';
import * as animation from './functions/_animation';
//import * as bliderBox from './functions/_bliderBox';
import * as layout from './functions/_layout';
//import * as offcanvas from './functions/_offcanvas';
//import * as pagetop from './functions/_pagetop';
//import * as smoothScroll from './functions/_smoothScroll';
import 'slick-carousel';
//import Modal from '../../node_modules/bootstrap/js/dist/modal'

let WIN_WIDTH                 = window.innerWidth;
let WIN_HEIGHT                = window.innerHeight;
let CURRENT_SC_OFFSET_X       = window.pageXOffset;
let CURRENT_SC_OFFSET_Y       = window.pageYOffset;
let BLAKEPOINT_HEADER_SP_MODE = 1024;

//offcanvas.setOffcanvas(BLAKEPOINT_HEADER_SP_MODE,'app');
// bliderBox.setBliderBox();
// smoothScroll.setSmoothScroll();

window.addEventListener('scroll', () => {
  CURRENT_SC_OFFSET_X = window.pageXOffset;
  CURRENT_SC_OFFSET_Y = window.pageYOffset;
  //引数：固定,固定,ボタンを出現させるきっかけとなる要素のID名,ボタンを固定させるきっかけとなる要素のID名
  //pagetop.setScrolltopBtn(WIN_HEIGHT,CURRENT_SC_OFFSET_Y,'mv','footer');
})

window.addEventListener('resize', () => {
  WIN_WIDTH = window.innerWidth;
  WIN_HEIGHT = window.innerHeight;
})


$(function (){
  // topページ用のスライドショー
  // ============================================================

  $('#js_heroSlider').slick({
    vertical: true,
    dots:     true,
    autoplay: true,
    arrows:   false,
    verticalSwiping: true,
    dotsClass: 'heroSlider_dots'
  });

  // マップ切り替え
  // ============================================================


  $('.js_mapChangeTrigger').on('click',function(){
    let $parentElm = $('.map_container');
    if($parentElm.hasClass('is_jsActive01')){
      //1がアクティブ -> 2をアクティブに
      $('.js_mapChangeTarget01').fadeOut();
      $('.js_mapChangeTarget02').fadeIn();
      $parentElm.removeClass('is_jsActive01').addClass('is_jsActive02');
    }else{
      $('.js_mapChangeTarget02').fadeOut();
      $('.js_mapChangeTarget01').fadeIn();
      $parentElm.removeClass('is_jsActive02').addClass('is_jsActive01');
    }
  })
});